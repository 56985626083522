import React from 'react';
import Row from './StartPageRow';
import Box from './StartPageBox';
import Title from './StartPageTitle';
import styled from 'react-emotion';

const Container = styled('div')`
  ${({ theme }) => theme.below.sm} {
    .row-component > div {
      flex-direction: row;
      flex-wrap: wrap;
      .box-component {
        :first-child {
          margin-right: 0.375rem;
        }
        :nth-child(2) {
          margin-left: 0.375rem;
          margin-top: 0;
        }
        :first-child,
        :nth-child(2) {
          height: 59.7333vw;
          width: calc(50% - 0.375rem);
        }
        :last-child {
          width: 100%;
          margin-bottom: 0;
          height: 122.4vw;
        }
      }
    }
  }
`;

const StartPageCategories = ({
  backgroundImageUrl1,
  backgroundImageUrl2,
  backgroundImageUrl3,
  buttonText1,
  buttonText2,
  buttonText3,
  buttonUrl1,
  buttonUrl2,
  buttonUrl3,
  title,
  subtitle,
  ...props
}) => (
  <Container className="categories-component" style={props}>
    <Title title={title} subtitle={subtitle} />
    <Row>
      <Box
        backgroundImageUrl={backgroundImageUrl1}
        buttonText={buttonText1}
        buttonUrl={buttonUrl1}
        sizes={[1 / 2, 1 / 2, 1 / 3, 1 / 3, '25rem']}
      />
      <Box
        backgroundImageUrl={backgroundImageUrl2}
        buttonText={buttonText2}
        buttonUrl={buttonUrl2}
        sizes={[1 / 2, 1 / 2, 1 / 3, 1 / 3, '25rem']}
      />
      <Box
        backgroundImageUrl={backgroundImageUrl3}
        buttonText={buttonText3}
        buttonUrl={buttonUrl3}
        sizes={[1, 1, 1 / 3, 1 / 3, '25rem']}
      />
    </Row>
  </Container>
);

export default StartPageCategories;
