import React from 'react';
import styled from 'react-emotion';
import Image from '@jetshop/ui/Image/Image';
import { TrendLink } from '../../ui/Button';
import propNameToFlexName from '../../../utils/propNameToFlexName';

const Box = styled('div')`
  flex: 1;
  flex-basis: ${({ wide }) => (wide ? '33.3333%' : 'auto')};
  margin: 0 0.375rem;
  position: relative;

  .container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: ${({ alignY }) => alignY};
    align-items: ${({ alignX }) => alignX};
  }

  h2 {
    font-family: ${({ theme }) => theme.fonts.secondary};
    text-align: center;
    letter-spacing: 0.01em;
    line-height: 140%;
    font-size: 2.875rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }

  span {
    text-transform: lowercase;
    font-variant: small-caps;
    letter-spacing: 0.1em;
    font-size: 0.875rem;
    line-height: 140%;
    font-weight: 500;
    text-align: center;
  }

  ${({ theme }) => theme.below.lg} {
    .container {
      padding: 2rem;
    }
  }

  ${({ theme }) => theme.below.md} {
    .container {
      padding: 1.5rem;
    }

    h2 {
      font-size: 2rem;
    }
  }

  ${({ theme }) => theme.below.sm} {
    height: 133.3333vw;
    margin: 0.375rem 0;
    :first-child {
      margin-top: 0;
    }
    :last-child {
      margin-bottom: 0;
    }
    .container {
      align-items: center;
    }
  }
`;

const StyledButton = styled(TrendLink)`
  width: 236px;
  height: 50px;
`;

const StartPageBox = ({
  buttonText,
  buttonUrl,
  alignX = 'center',
  alignY = 'bottom',
  wide = false,
  backgroundImageUrl,
  title,
  text,
  sizes
}) => {
  alignX = propNameToFlexName(alignX);
  alignY = propNameToFlexName(alignY);
  return (
    <Box
      className={`box-component ${wide ? 'wide' : ''}`}
      wide={wide}
      alignX={alignX}
      alignY={alignY}
    >
      {backgroundImageUrl && (
        <Image fillAvailableSpace src={backgroundImageUrl} sizes={sizes} />
      )}
      <div className="container">
        {text && <span>{text}</span>}
        {title && <h2>{title}</h2>}
        {buttonText && buttonUrl && (
          <StyledButton to={buttonUrl}>{buttonText}</StyledButton>
        )}
      </div>
    </Box>
  );
};

export default StartPageBox;
