import React from 'react';
import styled from 'react-emotion';
import MaxWidth from '../../Layout/MaxWidth';

const Row = styled('div')`
  display: flex;

  ${({ theme }) => theme.above.sm} {
    height: 39.0625vw;
    max-height: 500px;
    margin: 0 -0.375rem;
  }

  ${({ theme }) => theme.below.sm} {
    flex-direction: column;
  }
`;

const StartPageRow = ({ children, ...props }) => (
  <MaxWidth className="row-component">
    <Row style={props}>{children}</Row>
  </MaxWidth>
);

export default StartPageRow;
