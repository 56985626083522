import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackListEvent } from '@jetshop/core/analytics/tracking';
import React, { useEffect } from 'react';
import styled, { css, cx } from 'react-emotion';
import { Price } from '../Price';
import { ProductCard } from './ProductCard';
import { theme } from '../Theme';
import useShopId from '../../utils/useShopId';

export const priceStyle = css`
  ${Price.Wrapper} {
    display: flex;
    font-weight: 600;
    font-size: 1rem;
    font-family: ${theme.fonts.secondary};
    color: ${theme.colors.almostBlack};
    font-feature-settings: 'tnum' on, 'onum' on;
    margin-top: 0.25rem;
  }
  ${Price.New} {
    color: ${theme.colors.discountRed};
    margin-right: 0.375rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  ${Price.Old} {
    color: ${theme.colors.grey};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const Wrapper = styled('ul')`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-right: -0.375rem;
  margin-left: -0.375rem;

  ${priceStyle};

  .product-card {
    line-height: 19px;
    min-width: 0;

    width: 50%;
    ${({ theme }) => theme.above.md} {
      width: 33.333%;
    }
    ${({ theme }) => theme.above.lg} {
      width: 25%;
    }
  }
`;

export function ProductGrid({
  products,
  listName,
  loading,
  className,
  ...rest
}) {
  const track = useTracker();
  const isEztrad = useShopId() === 'eztrad';

  useEffect(() => {
    // Dont track anything if there are no products to render
    if (!products || products.length === 0) return;

    // Otherwise track a list view event
    track(trackListEvent({ listName, products }));
  }, [listName, products, track]);

  if (!products) return null;

  return (
    <Wrapper
      data-testid="product-grid"
      className={cx('product-grid', className)}
    >
      {products.map((product, index) => {
        return (
          <ProductCard
            key={index + ':' + product.articleNumber}
            product={product}
            style={{ opacity: loading ? 0.5 : 1 }}
            list={listName}
            {...rest}
          />
        );
      })}
    </Wrapper>
  );
}

export default ProductGrid;
