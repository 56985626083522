import React from 'react';
import MaxWidth from '../../Layout/MaxWidth';
import styled from 'react-emotion';

const Container = styled('div')`
  text-align: center;
  padding-bottom: 2rem;

  span {
    letter-spacing: 0.05em;
    font-variant: small-caps;
    font-weight: 500;
    font-size: 0.9375rem;
    text-transform: lowercase;
    margin-bottom: 0.5rem;
    display: block;
  }

  h2 {
    font-weight: 600;
    font-size: 2.875rem;
    letter-spacing: 0.01em;
    font-family: ${({ theme }) => theme.fonts.secondary};
  }

  ${({ theme }) => theme.below.sm} {
    padding-bottom: 1.5rem;

    h2 {
      font-size: 2rem;
    }
  }
`;

const StartPageTitle = ({ title, subtitle }) => (
  <Container className="title-component">
    <MaxWidth>
      {subtitle && <span>{subtitle}</span>}
      {title && <h2>{title}</h2>}
    </MaxWidth>
  </Container>
);

export default StartPageTitle;
