import React from 'react';
import styled from 'react-emotion';
import MaxWidth from '../../Layout/MaxWidth';
import Image from '@jetshop/ui/Image/Image';
import { TrendLink } from '../../ui/Button';
import { Above } from '@jetshop/ui/Breakpoints';

const BackgroundContainer = styled('div')`
  height: 600px;
  ${({ theme }) => theme.below.md} {
    height: 400px;
  }
`;

const StartPageHeroWrapper = styled(MaxWidth)`
  flex-direction: row;
  align-items: center;

  > div {
    flex: 1;
  }

  .column-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.green};
    padding: 2rem;
    width: 100%;

    h1 {
      font-weight: 700;
      font-size: 2.875rem;
      line-height: 120%;
      text-align: center;
      letter-spacing: 0.01em;
      margin-bottom: 1.25rem;
    }

    p {
      font-family: ${({ theme }) => theme.fonts.secondary};
      font-size: 1.125rem;
      line-height: 140%;
      text-align: center;
      letter-spacing: 0.01em;
      margin-bottom: 3.75rem;
    }

    .button-container {
      display: flex;
      justify-content: center;
      margin: 0 -0.625rem;
      width: 100%;
    }
  }

  ${({ theme }) => theme.above.md} {
    height: 100%;
    .column-content {
      height: 454px;
      background-color: rgba(255, 255, 255, 0.7);
    }
  }

  ${({ theme }) => theme.below.md} {
    background-color: ${({ theme }) => theme.colors.white};
    .column-content {
      padding: 2rem 0.25rem;
      h1 {
        font-size: 1.875rem;
      }
      p {
        margin-bottom: 2.125rem;
      }
    }
  }
`;

const StyledButton = styled(TrendLink)`
  width: 172px;
  height: 50px;
  max-width: calc(50% - 0.625rem);
  margin: 0 0.625rem;
`;

const ContentColumn = ({
  title,
  description,
  buttonLeftText,
  buttonLeftUrl,
  buttonRightText,
  buttonRightUrl
}) => {
  const isButtonLeft = buttonLeftText && buttonLeftUrl;
  const isButtonRight = buttonRightText && buttonRightUrl;

  return (
    <div className="column column-content">
      {title && <h1>{title}</h1>}
      {description && <p>{description}</p>}
      {(isButtonLeft || isButtonRight) && (
        <div className="button-container">
          {isButtonLeft && (
            <StyledButton to={buttonLeftUrl}>{buttonLeftText}</StyledButton>
          )}
          {isButtonRight && (
            <StyledButton to={buttonRightUrl}>{buttonRightText}</StyledButton>
          )}
        </div>
      )}
    </div>
  );
};

const StartPageHero = props => {
  const { align, backgroundImageUrl } = props;
  return (
    <div className="hero-component">
      <Above breakpoint="md">
        {match =>
          match ? (
            <BackgroundContainer>
              <Image
                fillAvailableSpace={true}
                src={backgroundImageUrl}
                sizes={1}
              >
                <StartPageHeroWrapper className="wrapper">
                  {align === 'left' ? (
                    <>
                      <ContentColumn {...props} />
                      <div />
                    </>
                  ) : (
                    <>
                      <div />
                      <ContentColumn {...props} />
                    </>
                  )}
                </StartPageHeroWrapper>
              </Image>
            </BackgroundContainer>
          ) : (
            <>
              <BackgroundContainer>
                <Image
                  fillAvailableSpace={true}
                  src={backgroundImageUrl}
                  sizes={2}
                />
              </BackgroundContainer>

              <StartPageHeroWrapper className="wrapper">
                <ContentColumn {...props} />
              </StartPageHeroWrapper>
            </>
          )
        }
      </Above>
    </div>
  );
};

export default StartPageHero;
